<template>
  <div class="examination-show">
    <div class="back-link">
      <base-back-link :text="'診療項目詳細に戻る'" @click="goToTreatment" />
    </div>
    <div class="title">検査詳細</div>
    <div class="button-wrapper">
      <base-button-small-white v-if="!lookOnlyFlg" @click="goToEditPage"
        >編集</base-button-small-white
      >
      <base-button-small-red v-if="!lookOnlyFlg" @click="openDeletePopup"
        >削除</base-button-small-red
      >
    </div>
    <div class="content-wrapper">
      <div class="heading">基本項目</div>
      <div class="content">
        <div class="row">
          <div class="key">フォーマットNo</div>
          <div class="value" data-test="version">
            {{ displayFormat.version }}
          </div>
        </div>
        <div class="row">
          <div class="key">検査名</div>
          <div class="value" data-test="treatment-name">
            {{ treatment.name }}
          </div>
        </div>
        <div class="row">
          <div class="key">血液検査管理</div>
          <div class="value" data-test="blood-flg">
            {{ displayFormat.bloodFlg }}
          </div>
        </div>
        <div
          class="topic-class"
          v-for="topicClass in displayTopicClasses"
          :key="topicClass.id"
        >
          <div class="row">
            <div class="key">項目区分名</div>
            <div class="value">{{ topicClass.name }}</div>
          </div>
          <div class="topic" v-for="topic in topicClass.topics" :key="topic.id">
            <div class="row">
              <div class="key">項目名</div>
              <div class="value">{{ topic.name }}</div>
            </div>
            <div class="row">
              <div class="key">入力タイプ</div>
              <div class="value">{{ topic.examinationType }}</div>
            </div>
            <div class="row">
              <div class="key">単位</div>
              <div class="value">{{ topic.unit }}</div>
            </div>
            <div class="row">
              <div class="key">コメント</div>
              <div class="value">
                <div class="number" v-if="topic.examinationType === '数値'">
                  <div class="row">
                    <div class="key comment">通常時表示</div>
                    <div class="value">{{ topic.comments.comment }}</div>
                  </div>
                  <div class="row">
                    <div class="key comment">下限未満時表示</div>
                    <div class="value">{{ topic.comments.minMax[0] }}</div>
                    <div class="key comment">上限超過時表示</div>
                    <div class="value">{{ topic.comments.minMax[1] }}</div>
                  </div>
                </div>
                <div class="text" v-if="topic.examinationType === 'テキスト'">
                  <div class="row">
                    <div class="key comment">通常時表示</div>
                    <div class="value">{{ topic.comments.comment }}</div>
                  </div>
                </div>
                <div class="select" v-if="topic.examinationType === '選択'">
                  <div class="row">
                    <div class="key comment">通常時表示</div>
                    <div class="value">{{ topic.comments.comment }}</div>
                  </div>
                  <div
                    class="row"
                    v-for="(option, i) in topic.comments.options"
                    :key="i"
                  >
                    <div class="key comment">選択肢</div>
                    <div class="value">{{ option[0] }}</div>
                    <div class="key comment">選択時コメント</div>
                    <div class="value">{{ option[1] }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="key">
                <span
                  class="image-allow"
                  data-test="image-allow"
                  @click="toggleSpeciesShowFlg(topicClass.id, topic.id)"
                >
                  <img
                    src="@/assets/images/allow3.png"
                    class="bottom-allow"
                    v-show="topic.speciesShowFlg"
                  />
                  <img
                    src="@/assets/images/allow4.png"
                    class="right-allow"
                    v-show="!topic.speciesShowFlg"
                  />
                </span>
                参考基準値
              </div>
              <div
                class="collapsed value"
                data-test="collapsed"
                v-if="topic.speciesShowFlg === false"
                @click="toggleSpeciesShowFlg(topicClass.id, topic.id)"
              >
                ...
              </div>
              <div class="value species-wrapper" v-else>
                <div v-for="species in topic.species" :key="species.id">
                  <div class="row">
                    <div class="key">{{ species.name }}</div>
                    <div class="value">{{ species.reference }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <announce-popup
      v-if="popup.opened"
      :type="popup.type"
      :title="popup.title"
      :buttons="popup.buttons"
      :disabled="waitFlg"
      @cancel="cancelDelete"
      @decision="decideDelete"
      @close="closePopup"
      >{{ popup.message }}</announce-popup
    >
  </div>
</template>

<script>
import BaseBackLink from '@/components/parts/atoms/BaseBackLink'
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import BaseButtonSmallRed from '@/components/parts/atoms/BaseButtonSmallRed'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import SlideListRoute from '@/components/mixins/SlideListRoute'
import { mapGetters } from 'vuex'
import { toCircleOrCross } from '@/utils/convert_string'

export default {
  name: 'ExaminationShow',

  components: {
    BaseBackLink,
    BaseButtonSmallWhite,
    BaseButtonSmallRed,
    AnnouncePopup
  },

  mixins: [SlideListRoute],

  props: {
    originalId: { type: Number, required: true }
  },

  data() {
    return {
      displayTopicClasses: [],
      examinationFormatOriginalId: 0,
      popup: {
        buttons: ['閉じる'],
        message: '',
        opened: false,
        title: '',
        type: ''
      },
      waitFlg: false
    }
  },

  computed: {
    ...mapGetters({
      getExaminationFormat: 'examinationFormats/getDataByOriginalId',
      getTreatment: 'treatments/getDataByOriginalId',
      getTopicsByClassOriginalId:
        'examinationTopics/getDataByExaminationTopicClassOriginalId',
      storeSpecies: 'species/getData',
      lookOnlyFlg: 'auth/lookOnlyFlg'
    }),
    treatment() {
      const treatment = this.getTreatment(this.originalId)
      if (treatment) {
        return treatment
      } else {
        return {}
      }
    },
    examinationFormat() {
      return this.getExaminationFormat(this.examinationFormatOriginalId)
    },
    displayFormat() {
      const format = this.examinationFormat
      if (format) {
        return {
          version: format.version,
          bloodFlg: toCircleOrCross(format.bloodFlg)
        }
      } else {
        return {}
      }
    }
  },

  watch: {
    examinationFormat: function(newObject, oldObject) {
      const updated =
        !oldObject?.delFlg &&
        !newObject?.delFlg &&
        oldObject?.id < newObject?.id
      if (updated) {
        this.displayTopicClasses = this.makeDisplayTopicClasses()
      }
    }
  },

  created() {
    this.examinationFormatOriginalId = this.getTreatmentExaminationFormatOriginalId()
    this.displayTopicClasses = this.makeDisplayTopicClasses()
  },

  methods: {
    getTreatmentExaminationFormatOriginalId() {
      const examinationFormats = this.$store.getters[
        'examinationFormats/getDataByTreatmentOriginalId'
      ](this.originalId)
      const examinationFormatOriginalId = examinationFormats.reduce(
        (acc, cur) => {
          return cur.originalId > acc ? cur.originalId : acc
        },
        this.examinationFormatOriginalId
      )
      return examinationFormatOriginalId
    },
    makeDisplayTopicClasses() {
      const format = this.examinationFormat
      if (!format) return []
      const storeExaminationTopicClasses = this.$store.getters[
        'examinationTopicClasses/getData'
      ]
      const targetExaminationTopicClasses = storeExaminationTopicClasses.filter(
        v => v.examinationFormatOriginalId === format.originalId
      )
      const displayTopicClasses = targetExaminationTopicClasses.map(
        topicClass => {
          return { ...topicClass, topics: this.makeDisplayTopics(topicClass) }
        }
      )
      return displayTopicClasses
    },
    makeDisplayTopics(examinationTopicClass) {
      const targetExaminationTopics =
        this.$store.getters[
          'examinationTopics/getDataByExaminationTopicClassOriginalId'
        ](examinationTopicClass.originalId) ?? []
      const displayTopics = targetExaminationTopics.map(topic => {
        return {
          ...topic,
          comments: JSON.parse(topic.comments),
          species: this.makeDisplaySpecies(
            topic.originalId,
            topic.examinationType
          ),
          speciesShowFlg: true
        }
      })
      return displayTopics
    },
    makeDisplaySpecies(topicOriginalId, type) {
      const storeExaminationSpecies = this.$store.getters[
        'examinationSpecies/getData'
      ]
      const examinationSpecies = storeExaminationSpecies.filter(
        v => v.examinationTopicOriginalId === topicOriginalId
      )
      const examinationSpeciesBySpeciesId = {}
      examinationSpecies.forEach(s => {
        examinationSpeciesBySpeciesId[s.speciesId] = s
      })
      return this.storeSpecies.map(species => {
        if (examinationSpeciesBySpeciesId[species.id]) {
          const reference = examinationSpeciesBySpeciesId[species.id].reference
          const numFlg = type === '数値' && reference !== ''
          const min = numFlg ? reference.split(',')[0] : ''
          const max = numFlg ? reference.split(',')[1] : ''
          return {
            name: species.name,
            reference: min === '' && max === '' ? reference : `${min} – ${max}`
          }
        } else {
          return { name: species.name, speciesId: species.id, reference: '' }
        }
      })
    },
    toggleSpeciesShowFlg(topicClassId, topicId) {
      const topicClassIndex = this.displayTopicClasses.findIndex(
        v => v.id === topicClassId
      )
      const topics = this.displayTopicClasses[topicClassIndex].topics
      const topic = topics.find(v => v.id === topicId)
      topic.speciesShowFlg = !topic.speciesShowFlg
      this.displayTopicClasses.splice(topicClassIndex, 1, {
        ...this.displayTopicClasses[topicClassIndex]
      })
    },
    goToTreatment() {
      this.$router.push({
        path: `/settings/treatments/treatments/${this.originalId}`
      })
    },
    goToTreatments() {
      const query = this.mixinMakeTreatmentRouteQuery(
        'class',
        this.treatment.treatmentClassOriginalId
      )
      this.$router.push({ name: 'treatments', query })
    },
    goToEditPage() {
      this.$router.push({
        path: `/settings/treatments/treatments/${this.originalId}/examinations/edit`
      })
    },
    openDeletePopup() {
      this.popup.type = 'alert'
      this.popup.title = '注意'
      this.popup.message = '削除してもよろしいですか？'
      this.popup.buttons = ['削除しない', '削除する']
      this.popup.opened = true
    },
    cancelDelete() {
      this.popup.opened = false
    },
    async decideDelete() {
      this.waitFlg = true
      const id = this.examinationFormat.id
      const result = await this.$store.dispatch('examinationFormats/delete', id)
      if (result === true) {
        this.popup.type = 'success'
        this.popup.title = '完了'
        this.popup.message = '削除しました'
      } else {
        this.popup.type = 'failure'
        this.popup.title = '失敗'
        this.popup.message = '削除に失敗しました'
      }
      this.popup.buttons = ['閉じる']
      this.waitFlg = false
    },
    closePopup() {
      if (this.popup.type === 'success') {
        this.$store.dispatch('petorelu/okLeave')
        const treatment = this.getTreatment(this.originalId)
        if (treatment.delFlg) {
          this.goToTreatments()
        } else {
          this.goToTreatment()
        }
      }
      this.popup.opened = false
    }
  }
}
</script>

<style lang="scss" scoped>
.examination-show {
  margin-bottom: 24px;
  min-width: 800px;
  > .back-link {
    margin-left: -26px;
  }
  > .title {
    font-size: 20px;
    font-weight: bold;
    border-bottom: 1px solid #{$light-grey};
    margin-top: 20px;
    padding-bottom: 20px;
  }
  > .button-wrapper {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    gap: 0 20px;
  }
  > .content-wrapper {
    margin-top: 16px;
    > .heading {
      font-size: 15px;
      font-weight: bold;
      border-bottom: 1px solid #{$light-grey};
      padding-bottom: 20px;
    }
    > .content {
      padding-top: 25px;
      .row {
        margin-bottom: 25px;
        display: flex;
        width: 100%;
        > .key {
          width: 240px;
          font-size: 15px;
          &.comment {
            width: 120px;
            margin-right: 20px;
          }
        }
        > .value {
          flex: 1;
          font-size: 13px;
          word-break: break-all;
        }
      }
      .topic-class {
        background-color: #{$grey_f6};
        padding: 20px 40px;
        border-bottom: solid 30px #ffffff;
        .key {
          width: 220px;
          > .image-allow {
            display: inline-block;
            width: 16px;
            padding-right: 10px;
            cursor: pointer;
            > .bottom-allow {
              margin-top: 5px;
              width: 15px;
              height: 9px;
            }
            > .right-allow {
              width: 9px;
              height: 15px;
            }
          }
        }
        .topic {
          &:nth-child(n + 3) {
            padding-top: 20px;
            border-top: dashed 2px #{$light-grey};
          }
        }
        .collapsed {
          text-align: center;
          text-justify: center;
          cursor: pointer;
          background-color: #ffffff;
          width: 100%;
        }
        .species-wrapper {
          background-color: #ffffff;
          padding: 0 27px;
          width: 100%;
          .row {
            align-items: center;
            margin: 0;
            border-bottom: 1px solid #e0e0e0;
            min-height: 73px;
            > .key {
              width: 120px;
              margin-right: 10px;
              word-break: break-all;
            }
            > .value {
              word-break: break-all;
            }
          }
        }
      }
    }
  }
}
</style>
