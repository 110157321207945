var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"treatment-medicine-form-in-popup"},[_c('div',{staticClass:"treatment-medicine-form"},[_vm._m(0),_c('div',{staticClass:"table-body"},[_c('div',{staticClass:"row item-name",attrs:{"title":_vm.itemName}},[_vm._v(_vm._s(_vm.itemName))]),_c('div',{staticClass:"row tax-exempt-flg"},[_c('base-select-box',{attrs:{"selectData":_vm.selectData,"styles":{ height: '28px' }},model:{value:(_vm.treatmentItem.taxExemptFlg),callback:function ($$v) {_vm.$set(_vm.treatmentItem, "taxExemptFlg", $$v)},expression:"treatmentItem.taxExemptFlg"}})],1),_c('div',{staticClass:"row anicom-flg"},[_c('base-select-box',{attrs:{"selectData":_vm.selectData,"styles":{ height: '28px' }},model:{value:(_vm.treatmentItem.anicomFlg),callback:function ($$v) {_vm.$set(_vm.treatmentItem, "anicomFlg", $$v)},expression:"treatmentItem.anicomFlg"}})],1),_c('div',{staticClass:"row ipet-flg"},[_c('base-select-box',{attrs:{"selectData":_vm.selectData,"styles":{ height: '28px' }},model:{value:(_vm.treatmentItem.ipetFlg),callback:function ($$v) {_vm.$set(_vm.treatmentItem, "ipetFlg", $$v)},expression:"treatmentItem.ipetFlg"}})],1),_c('div',{staticClass:"row unit-price"},[_c('validation-provider',{staticClass:"validate-provider",attrs:{"rules":{ intRule: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('base-text-box',{attrs:{"value":_vm.treatmentItem.unitPrice,"maxlength":7,"styles":_vm.textBoxStyles,"fullWidthNumbersToHalfWidthNumbers":true},on:{"input":function($event){return _vm.inputNum($event, 'unitPrice')},"blur":function($event){return _vm.blur('unitPrice')}}}),(errors[0])?_c('div',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1),_c('div',{staticClass:"row amount"},[_c('validation-provider',{staticClass:"validate-provider",attrs:{"rules":_vm.amountValidation},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('base-text-box',{attrs:{"value":_vm.treatmentItem.amount,"maxlength":5,"styles":_vm.textBoxStylesNarrow,"fullWidthNumbersToHalfWidthNumbers":true},on:{"input":function($event){return _vm.inputNum($event, 'amount')},"blur":function($event){return _vm.blur('amount')}}}),(errors[0])?_c('div',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1),_c('div',{staticClass:"row discount-rate"},[_c('validation-provider',{staticClass:"validate-provider",attrs:{"rules":{ intRule: true, percentRule: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('base-text-box',{attrs:{"value":_vm.treatmentItem.discountRate,"maxlength":3,"styles":_vm.textBoxStylesNarrow,"fullWidthNumbersToHalfWidthNumbers":true},on:{"input":function($event){return _vm.inputNum($event, 'discountRate')},"blur":function($event){return _vm.blur('discountRate')}}}),(errors[0])?_c('div',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1),_c('div',{staticClass:"row discount-price"},[_c('validation-provider',{staticClass:"validate-provider",attrs:{"rules":{ intRule: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('base-text-box',{attrs:{"value":_vm.treatmentItem.discountPrice,"maxlength":7,"styles":_vm.textBoxStyles,"fullWidthNumbersToHalfWidthNumbers":true},on:{"input":function($event){return _vm.inputNum($event, 'discountPrice')},"blur":function($event){return _vm.blur('discountPrice')}}}),(errors[0])?_c('div',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1),_c('div',{staticClass:"row total-price }"},[_vm._v(" "+_vm._s('￥' + _vm.totalPrice.toLocaleString())+" ")])])]),(
      this.treatmentItem.medicineOriginalId !== 0 && !_vm.prescriptionDisplayFlg
    )?_c('base-button-plus',{staticClass:"register-button-area",attrs:{"text":'処方追加'},on:{"click":function($event){return _vm.$emit('click')}}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-header"},[_c('div',{staticClass:"row item-name"},[_vm._v("診療項目内容")]),_c('div',{staticClass:"row tax-exempt-flg"},[_vm._v("非課税")]),_c('div',{staticClass:"row anicom-flg"},[_vm._v("アニコム")]),_c('div',{staticClass:"row ipet-flg"},[_vm._v("アイペット")]),_c('div',{staticClass:"row unit-price"},[_vm._v("単価（￥）")]),_c('div',{staticClass:"row amount"},[_vm._v("数量")]),_c('div',{staticClass:"row discount-rate"},[_vm._v("割引（%）")]),_c('div',{staticClass:"row discount-price"},[_vm._v("値引（￥）")]),_c('div',{staticClass:"row total-price }"},[_vm._v("計（￥）")])])}]

export { render, staticRenderFns }