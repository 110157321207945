<template>
  <div class="examination-edit">
    <validation-observer class="validation-observer" v-slot="{ invalid }">
      <div class="title">検査編集</div>
      <div class="contents">
        <div class="content-wrap">
          <div class="heading">基本項目</div>
          <div class="content">
            <examination-format-input-form
              :format="format"
              :topicClasses="displayTopicClasses"
              :treatment="treatment"
              @addOption="addOption"
              @addTopic="addTopic"
              @addTopicClass="addTopicClass"
              @removeOption="removeOption"
              @removeTopic="removeTopic"
              @selectType="selectType"
              @toggleSpeciesShowFlg="toggleSpeciesShowFlg"
            />
          </div>
        </div>
        <div class="button-wrap">
          <base-button-medium-white @click="goToPageExamination"
            >戻る</base-button-medium-white
          >
          <base-button-medium-orange
            v-if="lookOnlyFlg === 0"
            :disabled="invalid || waitFlg"
            @click="updateData"
            >登録</base-button-medium-orange
          >
        </div>
      </div>
      <announce-popup
        v-if="popup.opened"
        :type="popup.type"
        :title="popup.title"
        :buttons="popup.buttons"
        @close="closePopup"
        >{{ popup.message }}</announce-popup
      >
      <unsaved-leave-popup />
    </validation-observer>
  </div>
</template>

<script>
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import BaseButtonMediumOrange from '@/components/parts/atoms/BaseButtonMediumOrange'
import BaseButtonMediumWhite from '@/components/parts/atoms/BaseButtonMediumWhite'
import CheckInputDifference from '@/components/mixins/CheckInputDifference'
import ExaminationFormatInputForm from '@/components/parts/organisms/ExaminationFormatInputForm'
import SlideListRoute from '@/components/mixins/SlideListRoute'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import _ from 'lodash'
import { ValidationObserver } from 'vee-validate'
import { mapGetters } from 'vuex'

export default {
  name: 'ExaminationEdit',

  components: {
    AnnouncePopup,
    BaseButtonMediumOrange,
    BaseButtonMediumWhite,
    ExaminationFormatInputForm,
    UnsavedLeavePopup,
    ValidationObserver
  },

  mixins: [CheckInputDifference, SlideListRoute],

  props: {
    originalId: { type: Number }
  },

  data() {
    return {
      displayTopicClasses: [],
      displayTopics: [],
      format: {},
      optionKey: 1,
      popup: {
        buttons: ['閉じる'],
        message: '',
        opened: false,
        title: '',
        type: ''
      },
      shouldGoToPageExamination: false,
      shouldGoToPageTreatment: false,
      shouldGoToPageTreatments: false,
      topicClassKey: 1,
      topicKey: 1,
      treatment: {},
      waitFlg: false
    }
  },

  computed: {
    ...mapGetters({
      getTreatment: 'treatments/getDataByOriginalId',
      examinationFormats: 'examinationFormats/getData',
      examinationTopicClasses: 'examinationTopicClasses/getData',
      topicsByClassOriginalId:
        'examinationTopics/getDataByExaminationTopicClassOriginalId',
      examinationSpecies: 'examinationSpecies/getData',
      storeSpecies: 'species/getData',
      lookOnlyFlg: 'auth/lookOnlyFlg'
    })
  },

  created() {
    this.treatment = { ...this.getTreatment(this.originalId) }
    this.format = {
      ...this.examinationFormats.find(
        v => v.treatmentOriginalId === this.treatment.originalId
      )
    }
    this.format.version++
    this.displayTopicClasses = this.makeDisplayTopicClasses()
    this.mixinInputData = {
      format: this.format,
      displayTopicClasses: this.displayTopicClasses
    }
    this.mixinSetInitialData()
  },

  methods: {
    goToPageExamination() {
      this.$router.push({
        name: 'examination-show',
        params: { originalId: this.originalId }
      })
    },
    goToPageTreatment() {
      this.$router.push({
        name: 'treatment-show',
        params: { originalId: this.originalId }
      })
    },
    goToPageTreatments() {
      const query = this.mixinMakeTreatmentRouteQuery(
        'class',
        this.treatment.treatmentClassOriginalId
      )
      this.$router.push({ name: 'treatments', query })
    },
    makeDisplayTopicClasses() {
      const topicClasses = this.examinationTopicClasses.filter(
        v =>
          v.examinationFormatOriginalId === this.format.originalId &&
          v.delFlg === 0
      )
      return topicClasses.map(topicClass => {
        return {
          ...topicClass,
          key: this.topicClassKey++,
          topics: this.makeDisplayTopics(topicClass.originalId)
        }
      })
    },
    makeDisplayTopics(topicClassOriginalId) {
      const examinationTopics = this.topicsByClassOriginalId(
        topicClassOriginalId
      )
      const topics = examinationTopics.map(topic => {
        const comments = JSON.parse(topic.comments)
        const displayOptions = comments.options.map(option => {
          return [...option, this.optionKey++]
        })
        return {
          ...topic,
          comments: { ...comments, options: displayOptions },
          species: this.makeDisplaySpecies(topic.originalId),
          typeId: this.typeConversion(topic.examinationType),
          key: this.topicKey++,
          speciesShowFlg: true
        }
      })
      return topics
    },
    makeDisplaySpecies(topicOriginalId) {
      const examinationSpecies = this.examinationSpecies.filter(
        v => v.examinationTopicOriginalId === topicOriginalId
      )
      const examinationSpeciesBySpeciesId = {}
      if (examinationSpecies.length !== 0) {
        examinationSpecies.forEach(
          s => (examinationSpeciesBySpeciesId[s.speciesId] = s)
        )
      }
      return this.storeSpecies.map(s => {
        return examinationSpeciesBySpeciesId[s.id] || this.baseSpecies(s.id)
      })
    },
    baseTopic() {
      const species = this.storeSpecies.map(v => {
        return this.baseSpecies(v.id)
      })
      return {
        id: 0,
        name: '',
        comments: this.baseComments(),
        species,
        unit: '',
        typeId: 0,
        examinationType: '数値',
        key: this.topicKey++,
        speciesShowFlg: true
      }
    },
    baseComments() {
      return {
        minMax: ['', ''],
        comment: '',
        options: [['', '', this.optionKey++]]
      }
    },
    baseSpecies(speciesId) {
      return { id: 0, speciesId, reference: '' }
    },
    async updateData() {
      this.waitFlg = true
      let topicClasses = _.cloneDeep(this.displayTopicClasses)
      topicClasses.forEach(topicClass => {
        let topics = []
        topicClass.topics.forEach(topic => {
          delete topic.key
          delete topic.speciesShowFlg
          delete topic.typeId
          topic.comments.options.forEach(o => o.splice(2, 1))
          topic.comments = JSON.stringify(topic.comments)
          topics.push(topic)
        })
        delete topicClass.key
      })
      const result = await this.$store.dispatch('examinationFormats/update', {
        examinationFormat: this.format,
        examinationTopicClasses: topicClasses
      })
      if (result === 'success') {
        this.popup.type = 'success'
        this.popup.title = '完了'
        this.popup.message = '編集しました'
        this.shouldGoToPageExamination = true
        this.mixinSetInitialData()
      } else {
        this.popup.type = 'failure'
        this.popup.title = '失敗'
        if (result === 'no treatment') {
          this.popup.message =
            '編集に失敗しました。\n対象の診療項目は既に削除されています。'
          this.shouldGoToPageTreatments = true
        } else if (result === 'no examinationFormat') {
          this.popup.message =
            '編集に失敗しました。\n対象の検査は既に削除されています。'
          this.shouldGoToPageTreatment = true
        } else {
          this.popup.message = '編集に失敗しました'
        }
      }
      this.popup.opened = true
      this.waitFlg = false
    },
    closePopup() {
      if (this.shouldGoToPageExamination) {
        this.$store.dispatch('petorelu/okLeave')
        this.goToPageExamination()
      } else if (this.shouldGoToPageTreatment) {
        this.$store.dispatch('petorelu/okLeave')
        this.goToPageTreatment()
      } else if (this.shouldGoToPageTreatments) {
        this.$store.dispatch('petorelu/okLeave')
        this.goToPageTreatments()
      }
      this.popup.opened = false
    },
    typeConversion(val) {
      const types = ['数値', 'テキスト', '選択']
      if (typeof val === 'string') {
        return types.findIndex(v => v === val)
      } else {
        return types[val]
      }
    },
    addTopicClass() {
      this.displayTopicClasses.push({
        id: 0,
        name: '',
        key: this.topicClassKey++,
        topics: [this.baseTopic()]
      })
    },
    addTopic(topicClassKey) {
      const index = this.displayTopicClasses.findIndex(
        v => v.key === topicClassKey
      )
      const newTopics = [
        ...this.displayTopicClasses[index].topics,
        this.baseTopic()
      ]
      this.displayTopicClasses.splice(index, 1, {
        ...this.displayTopicClasses[index],
        topics: newTopics
      })
    },
    removeTopic({ topicClassKey, topicKey }) {
      const topicClassIndex = this.displayTopicClasses.findIndex(
        v => v.key === topicClassKey
      )
      const topics = this.displayTopicClasses[topicClassIndex].topics
      if (topics.length === 1) {
        this.displayTopicClasses.splice(topicClassIndex, 1)
      } else {
        const topicIndex = topics.findIndex(v => v.key === topicKey)
        topics.splice(topicIndex, 1)
        this.displayTopicClasses.splice(topicClassIndex, 1, {
          ...this.displayTopicClasses[topicClassIndex],
          topics
        })
      }
    },
    addOption({ topicClassKey, topicKey }) {
      const topicClassIndex = this.displayTopicClasses.findIndex(
        v => v.key === topicClassKey
      )
      const topics = this.displayTopicClasses[topicClassIndex].topics
      const comments = topics.find(v => v.key === topicKey).comments
      comments.options = [...comments.options, ['', '', this.optionKey++]]
      this.displayTopicClasses.splice(topicClassIndex, 1, {
        ...this.displayTopicClasses[topicClassIndex]
      })
    },
    removeOption({ topicClassKey, topicKey, optionKey }) {
      const topicClassIndex = this.displayTopicClasses.findIndex(
        v => v.key === topicClassKey
      )
      const topics = this.displayTopicClasses[topicClassIndex].topics
      const options = topics.find(v => v.key === topicKey).comments.options
      const optionIndex = options.findIndex(v => v[2] === optionKey)
      options.splice(optionIndex, 1)
      this.displayTopicClasses.splice(topicClassIndex, 1, {
        ...this.displayTopicClasses[topicClassIndex],
        topics
      })
    },
    selectType({ topicClassKey, topicKey, typeId }) {
      const topicClassIndex = this.displayTopicClasses.findIndex(
        v => v.key === topicClassKey
      )
      const topics = this.displayTopicClasses[topicClassIndex].topics
      const topicIndex = topics.findIndex(v => v.key === topicKey)
      const examinationType = this.typeConversion(typeId)
      const species = this.storeSpecies.map(v => {
        return this.baseSpecies(v.id)
      })
      topics.splice(topicIndex, 1, {
        ...topics[topicIndex],
        typeId,
        examinationType,
        comments: this.baseComments(),
        species
      })
      this.displayTopicClasses.splice(topicClassIndex, 1, {
        ...this.displayTopicClasses[topicClassIndex]
      })
    },
    toggleSpeciesShowFlg({ topicClassKey, topicKey }) {
      const topicClassIndex = this.displayTopicClasses.findIndex(
        v => v.key === topicClassKey
      )
      const topics = this.displayTopicClasses[topicClassIndex].topics
      const topic = topics.find(v => v.key === topicKey)
      topic.speciesShowFlg = !topic.speciesShowFlg
      this.displayTopicClasses.splice(topicClassIndex, 1, {
        ...this.displayTopicClasses[topicClassIndex]
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.examination-edit {
  width: 100%;
  text-align: left;
  > .validation-observer {
    > .title {
      font-size: 20px;
      font-weight: bold;
      border-bottom: 1px solid #{$light-grey};
      padding-bottom: 20px;
      width: 100%;
      min-width: 700px;
    }
    > .contents {
      margin-top: 20px;
      > .content-wrap {
        > .heading {
          font-size: 15px;
          font-weight: bold;
          border-bottom: 1px solid #{$light-grey};
          padding-bottom: 20px;
          min-width: 700px;
        }
        > .content {
          margin-top: 25px;
        }
      }
      > .button-wrap {
        display: flex;
        justify-content: center;
        gap: 0 32px;
        margin: 60px 0;
      }
    }
  }
}
</style>
